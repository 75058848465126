import * as React from 'react';
import Button from '@mui/material/Button';
import logo from './logo.svg';
import './App.css';
class App extends React.Component {

  state = {
    language: window.localStorage.getItem('lang') ?? 'cn',
  }
  render() {
    const menu_txt = [
      { 'cn': '首頁', 'en': 'HOME' },
      { 'cn': '𓁿SURIEL', 'en': '𓁿SURIEL' },
      { 'cn': '攝影', 'en': 'PHOTOGRAPHY' },
      { 'cn': '插圖', 'en': 'ILLUSTRATION' },
      { 'cn': '設計', 'en': 'DESIGN' },
      { 'cn': '開發', 'en': 'DEVELOP' },
      { 'cn': '聯絡', 'en': 'CONTACT' },
    ]
    return (
      <div className="App">
        <div className='header'>
          <img src={logo} className="App-logo" alt="logo" />
          <div className='lang-and-menu' >
            {/* 按钮 */}
            <div className='languages'>
              <Button
                variant="text"
                size="small"
                color={this.state.language == 'cn' ? 'secondary' : 'primary'}
                onClick={() => {
                  this.setState({ language: 'cn' })
                  window.localStorage.setItem('lang', 'cn')
                }}>
                中文
              </Button>
              <Button
                variant="text"
                size="small"
                color={this.state.language == 'en' ? 'secondary' : 'primary'}
                onClick={() => {
                  this.setState({ language: 'en' })
                  window.localStorage.setItem('lang', 'en')
                }}>
                ENG
              </Button>
            </div>
            <div className='menu'>
              {menu_txt.map(v => (<div className='menu_item'>{v[this.state.language]}</div>))}
            </div>
          </div>

        </div>

      </div>
    );
  }
}
export default App;
